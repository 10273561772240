import $ from 'jquery';
import 'bootstrap';
import Rails from '@rails/ujs';
import 'social-likes';
import { installPageControls } from './legacy-javascript/notice_bar'
import { installContactForm } from './legacy-javascript/contact_register'
import { initImageMap } from './legacy-javascript/picturemap'
import { lessRefreshUserActionBar } from './legacy-javascript/user_action_bar'
import { initCompanyForm } from './legacy-javascript/company_form'
import { installEmailChecker } from './legacy-javascript/email_taken'
import { installEventHandlers } from './legacy-javascript/events'

import { installPopupHandlers } from './popup'
import popup_from_url from './popup_from_url'
import installSearching from './searching'
import { installAutoCompleteText } from "./auto_complete_text";
import {installFixToc} from "./jekyll_reader";
import "./searching";
import { initVideoSearch } from './video_search'
import './plyr_init'
import 'select2/dist/css/select2.css';
import "./category_select"
import { initPlaylistImport } from './playlist_import'

function installKeys() {
  $(document).keypress(function(e) {
      if ((e.keyCode || e.which) == 13) {
          // Enter key pressed
          $('a.less-enter-trigger').trigger('click');
          // below is a hack fix.
          // Because a bug in jQuery, the above line doesn't work.
          // But I still want to keep it. So later the following
          // line can be deleted and see if the enter for link
          // feature still work. If still work, the following line
          // can be deleted then.
          try {
            document.getElementsByClassName("less-enter-trigger")[0].click();
          }
          catch(err) {}
      }
  });
}

function installCopy() {
  $(".copy-to-clipboard").on('click', function() {
    $(this).prev("input").select();
    document.execCommand('copy');
    return false;
  });
}

if(document.getElementsByTagName("BODY")[0].classList.contains('user-logged-in')) {
  document.addEventListener('DOMContentLoaded', popup_from_url);
}

function installOffcanvas() {
  $("#toggle").on("click", function() {
    $(".row-offcanvas").toggleClass("active");
  });
}

/**
 * Function that tracks a click on an outbound link in Google Analytics.
 */
const trackOutboundLink = function( eventname, tag) {
  gtag('event', eventname, {
    'lead_source': "website",
    'tag': tag,
    'debug_mode': true
  });
};
window.trackOutboundLink = trackOutboundLink;


function installMainMenuTopLevelLinks() {
  $('.dropdown a').on('click', function(e) {
    var url = $(this).attr('href');
    if (url) {
      window.location.href = url;
    }
  });
}

Rails.start();
installMainMenuTopLevelLinks();
installPageControls();
installContactForm();
initImageMap();
lessRefreshUserActionBar();
initCompanyForm();
installEmailChecker();
installPopupHandlers();
installEventHandlers();
installOffcanvas();
installSearching();
installKeys();
installCopy();
installFixToc();
installAutoCompleteText();
initVideoSearch()
initPlaylistImport()
